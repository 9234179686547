import { API_URL } from '~/const/env'

export function makeFilePath (path: string) {
  if (path != null) {
    return path.replace('..', API_URL)
  }
  return ''
}

export function editImageSource (contents: string) {
  const parser = new DOMParser()
  const document = parser.parseFromString(contents, 'text/html')
  const imgTags = document.getElementsByTagName('img')
  for(let i = 0; i < imgTags.length; i++) {
    const imgPath = makeFilePath(imgTags[i].getAttribute('src') as string)
    imgTags[i].setAttribute('src', imgPath)
  }
  return document.documentElement.innerHTML
}
